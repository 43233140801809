<script>

export default {
  name: 'ParticiapntList',
  components: {},

  props: {
    participants: Array,
    isSingleView: Boolean
  },

  data() {
    return {}
  },

  methods: {},

  computed: {

    filteredParticipants() {

      if (this.participants) {
        return this.participants.filter(participant => {
          return participant.participated === '1';
        })
      }
      return [];

    },

    checkedInCount() {
      if (this.isSingleView) {
        return `(${this.filteredParticipants ? this.filteredParticipants.length : 0})`;
      }

      return '';
    }
  }
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1 class="fw-bolder h1__list" :class="{'h1__single': isSingleView}">Derzeit eingecheckt {{ checkedInCount }}</h1>
        <hr class="hr__headline mt-1 mb-3">
      </div>
    </div>

    <div class="participants-table" :class="{'table__single': isSingleView}">
      <div v-for="participant in filteredParticipants" :key="participant.uid" class="table-entry scale-in-hor-left">
        {{ participant.firstname }} {{ participant.lastname }}
      </div>

    </div>
  </div>
</template>


<style lang="scss">

.participants-table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  height: 60vh;
}

.table-entry {
  &:nth-child(odd) {
    background: #28a43a linear-gradient(180deg, #48b258, #28a43a) repeat-x;
    border-color: #28a43a;
    color: white;
  }
}


.scale-in-hor-left {
  -webkit-animation: scale-in-hor-left 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-hor-left 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

</style>
