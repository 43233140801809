<script>
import {ApiConfig} from "@/config/api-config";
import CourseComponent from "@/components/CourseComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import WeightChamberComponent from "@/components/WeightChamberComponent.vue";
import CourseComponentSingle from "@/components/CourseComponentSingle.vue";

export default {
  name: 'BaseWrapper',
  components: {
    CourseComponentSingle,
    WeightChamberComponent,
    CourseComponent,
    HeaderComponent
  },

  data() {
    return {
      readyToRender: false,
      location: null,
      layout: null,
      locationName: null,
      courses: null,
      timeslots: null,
      trigger: null,
      layoutOptions: [{value: '', name: 'Bitte wähle ein Layout'}, {value: '1', name: 'Kurs-Layout'}, {
        value: '2',
        name: '3er Split'
      }],
      options: [{value: '', name: 'Bitte Raum wählen'}, {value: '3', name: 'Kursraum 1'}, {
        value: '4',
        name: 'Kursraum 2'
      }, {value: '5', name: 'Kraftkammer'}, {value: '999', name: 'Splitview'}]
    }
  },

  mounted() {
    this.checkRouteAndSetLocation();
    this.update();
    this.loadFromLocalStorageIfNothingHappens();
  },

  methods: {

    checkRouteAndSetLocation() {
      const locationId = window.location.pathname.replace('/', '');
      if (locationId) {

        /**
         * mapping the slugs to the typo3 id values
         * @type {{kk: number, "1": number, "2": number, sv: number}}
         */
        const locationMapper = {
          1: 3,
          2: 4,
          kk: 5,
          sv: 999
        }

        this.location = locationMapper[locationId];
        this.approveInput();
      }


    },

    approveInput() {
      if (this.location) {
        this.fetchCourses();

        const location = this.options.find(option => {
          return option.value == this.location;
        });

        if (location) {
          this.locationName = location.name;
          this.readyToRender = true;
        }
      }
    },

    setLocation(e) {
      if (e.target.value) {
        this.location = parseInt(e.target.value);
        localStorage.location = this.location;
      }
    },

    loadFromLocalStorageIfNothingHappens() {
      setTimeout(() => {

        if(!this.location) {
          this.location = parseInt(localStorage.location);
          this.layout = parseInt(localStorage.layout);
          this.approveInput();
        }

      }, 120000);//2minutes
    },

    update() {
      setInterval(() => {
        this.fetchCourses();
      }, 10000)
    },

    async fetchCourses() {
      try {
        const response = await fetch(ApiConfig.getUrl + ApiConfig.endpoints.currentCourses, {
          method: 'POST',

          body: JSON.stringify({
            token: ApiConfig.token,
            payload: {
              isCheckIn: this.location === 999,
            }
          })
        });
        const results = await response.json();
        this.courses = results[0].payload;
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("Server Error:", err)
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("Network Error:", err)
        } else {
          console.log("Client Error:", err)
        }
      }
    }
  },

}
</script>

<template>
  <div v-if="readyToRender">
    <div v-if="location !== 999 && location !== 5">
      <CourseComponentSingle v-if="courses" :location="location" :location-name="locationName" :courses="courses"
                       is-single-view></CourseComponentSingle>
    </div>

    <div v-if="location === 5">
      <WeightChamberComponent is-single-view></WeightChamberComponent>
    </div>


    <div v-if="location === 999">
      <div class="d-flex split-view__container">
        <div class="split-view__course-container">
          <CourseComponent v-if="courses" :layout="2" :location="3" location-name="Kursraum 1"
                           :courses="courses"></CourseComponent>
        </div>
        <div class="split-view__course-container background__ligth">
          <CourseComponent v-if="courses" :layout="2" :location="4" :render-clock="true" location-name="Kursraum 2"
                           :courses="courses"></CourseComponent>
        </div>
<!--        <div class="split-view__course-container justify-content-start">-->
<!--          <WeightChamberComponent></WeightChamberComponent>-->
<!--        </div>-->
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col-2 position-relative">
          <div class="header__split-view">
            <HeaderComponent></HeaderComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="center">
    <h1>Hallo Marco!</h1>
    <select class="mt-4" @change="setLocation">
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.name }}
      </option>
    </select>

    <button class="mt-4" type="button" @click="approveInput">Bestätigen</button>

  </div>

</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.split-view__container {
  justify-content: center;
}

.background__ligth {
  background: rgb(245, 245, 245);
}

.header {
  &__split-view {
    position: absolute;
    right: 1rem;
    left: 1rem;
    bottom: 1rem;
  }
}

.center {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column;
}

table {
  margin: 1rem 2.5rem 2.5rem;
  table-layout: fixed;
  border-collapse: collapse;
}

td {
  padding: 0.75rem;
  font-size: 1.25rem;
}

tbody tr:nth-child(odd) {
  background: #28a43a linear-gradient(180deg, #48b258, #28a43a) repeat-x;
  border-color: #28a43a;
  color: white;
}

tbody tr:nth-child(even) {
  color: black;
  box-shadow: 0 15px 44px -8px rgba(0, 0, 0, 0.66);
}


</style>
