<script>


export default {
  name: 'CourseInfo',
  components: {},
  props: {
    currentCourse: Object,
    location: String,
    participantsCount: Number,
    layout: Number,
    isSingleView: Boolean,
    renderClock: Boolean
  },

  data() {
    return {
      clock: null,
      clockInterval: null,
      clockCounter: 0
    }
  },

  mounted() {
    this.startClock();
  },

  methods: {
    startClock() {

      this.clockInterval = setInterval(() => {
        this.updateClock();
        this.clockCounter++;

        if(this.clockCounter > 14000) { //~ 4h
          clearInterval(this.clockInterval);
          this.startClock();
        }
      }, 1000);


    },
    getTime() {

      const time = new Date();

      const formatter = Intl.DateTimeFormat('de-AT', {
        hour: "2-digit",
        minute: "2-digit"
      });
      console.log(time);
      return formatter.format(time);
    },

    updateClock() {
      this.clock = this.getTime();
    }
  },

  computed: {
    courseName() {
      if (this.currentCourse && this.currentCourse.course) {
        return this.currentCourse.course.name;
      }
      return 'Zeit für eine Pause!'
    },

    courseNumber() {
      if (this.currentCourse && this.currentCourse.course) {
        return this.currentCourse.course.number;
      }
      return ''
    },

    trainer() {
      if (this.currentCourse && this.currentCourse.course) {
        return this.currentCourse.course.trainer;
      }
      return '---'
    },

    time() {
      if (this.currentCourse && this.currentCourse.course) {

        const startTime = new Date(this.currentCourse.startTime.date)
        const endTime = new Date(this.currentCourse.endDateTime.date)

        const formatter = Intl.DateTimeFormat('de-AT', {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Europe/Vienna"
        });


        return `${formatter.format(startTime)} - ${formatter.format(endTime)} Uhr`
      }
      return '---'
    },

    courseNameNext() {
      if (this.nextCourse && this.nextCourse.course) {
        return this.nextCourse.course.name;
      }
      return 'derzeit findet kein Kurs statt'
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 title">
      <div class="">
        <h2 class="green" :class="{'h2__single': isSingleView}">{{ location }}</h2>
        <div v-if="renderClock" class="clock">{{ clock }}</div>
      </div>
    </div>
  </div>

  <div class="course-info">
    <div class="row">
      <div class="col-12">
        <h1 class="fw-bolder mb-0" :class="{'h1__single': isSingleView}">{{ courseName }}</h1>
        <hr class="hr__headline mt-1 mb-3">
      </div>
      <div class="col-12 course-property" :class="{'course-property__single': isSingleView}">
        Uhrzeit: {{ time }}
      </div>
    </div>

    <div class="row">
      <div class="col-12 course-property" :class="{'course-property__single': isSingleView}">
        Kursleiter:in: {{ trainer }}
      </div>
    </div>
  </div>

</template>


<style scoped lang="scss">

.green {
  color: #28a43a;
}

.course-property {

  &__single {
    font-size: 1.7rem;
  }
}

</style>
