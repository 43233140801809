
<script>
export default {
  name: 'HeaderComponent',
  components: {},
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 280 138.4" style="enable-background:new 0 0 253.8 138.4;" xml:space="preserve">
    <polygon class="st0 pulsate-fwd" points="253.7,23.1 226.4,32.7 230.8,38 219.4,44.1 183.8,0 130.2,45.7 118.3,27.8 61.7,68.5 39.9,56.6 0,91.8   39.9,66.5 63.7,79.8 119.3,41.7 134.2,71.5 183.8,12.9 213.2,60.1 234.8,42.8 239.5,48.3 "></polygon>
    <polygon class="st1" points="18.5,114.7 12.3,114.7 11.7,102.7 7.7,114.7 1.4,114.7 0.4,95.7 4.5,95.7 5.3,109.2 9.9,95.7   15.4,95.7 16.2,109.2 20.8,95.7 24.9,95.7 "></polygon>
    <polygon class="st1" points="30.8,95.7 28.2,114.7 24.1,114.7 26.8,95.7 "></polygon>
    <g>
	<defs>
		<rect id="SVGID_1_" x="-32.2" y="-27.6" width="320" height="197"></rect>
	</defs>
      <clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_" style="overflow:visible;"></use>
	</clipPath>
      <path class="st2" d="M39.2,107.9h-2.7l-1,6.8h-4.1l2.7-19h8.1c3.2,0,4.8,1.4,4.8,4.2c0,0.4,0,0.8-0.1,1.2l-0.2,1.4   c-0.3,2.2-1.4,3.8-3.1,4.7l1.4,7.5h-4.1L39.2,107.9z M37.6,99.7l-0.6,4.1h4.1c0.9,0,1.4-0.5,1.5-1.4l0.2-1.4c0-0.1,0-0.2,0-0.3   c0-0.7-0.4-1-1.2-1H37.6z"></path>
      <path class="st2" d="M65.1,95.7c3.2,0,4.8,1.4,4.8,4.2c0,0.4,0,0.8-0.1,1.2c-0.3,2.4-1.3,3.8-2.9,4.1c1.2,0.2,1.8,1.2,1.8,2.8   c0,0.4,0,0.8-0.1,1.2c-0.5,3.6-2.6,5.4-6.2,5.4h-8.1l2.7-19H65.1z M59.4,107.2l-0.5,3.4H63c0.9,0,1.4-0.5,1.5-1.4l0.1-0.7   c0-0.1,0-0.2,0-0.3c0-0.7-0.4-1-1.2-1H59.4z M60.4,99.7l-0.5,3.4H64c0.9,0,1.4-0.5,1.6-1.4l0.1-0.7c0-0.1,0-0.2,0-0.3   c0-0.7-0.4-1-1.2-1H60.4z"></path>
      <polygon class="st2" points="84.9,103.1 84.4,107.2 76.2,107.2 75.7,110.6 83.9,110.6 83.3,114.7 71.1,114.7 73.8,95.7 86,95.7    85.4,99.7 77.3,99.7 76.8,103.1  "></polygon>
      <polygon class="st2" points="106,114.7 99.7,114.7 99.1,102.7 95.1,114.7 88.9,114.7 87.9,95.7 92,95.7 92.8,109.2 97.4,95.7    102.8,95.7 103.6,109.2 108.3,95.7 112.3,95.7  "></polygon>
      <polygon class="st2" points="125.4,103.1 124.8,107.2 116.7,107.2 116.2,110.6 124.3,110.6 123.8,114.7 111.6,114.7 114.2,95.7    126.4,95.7 125.9,99.7 117.7,99.7 117.2,103.1  "></polygon>
      <path class="st2" d="M131.9,109.2c0,0.1,0,0.2,0,0.3c0,0.7,0.4,1,1.2,1h2.7c0.9,0,1.4-0.5,1.5-1.4l0.3-2h-2.7l0.6-4.1h6.8l-0.9,6.1   c-0.5,3.6-2.6,5.4-6.2,5.4h-2.7c-3.2,0-4.8-1.4-4.8-4.2c0-0.4,0-0.8,0.1-1.2l1.2-8.1c0.5-3.6,2.6-5.4,6.2-5.4h8.1l-0.6,4.1h-8.1   c-0.9,0-1.4,0.5-1.5,1.4L131.9,109.2z"></path>
      <polygon class="st2" points="157.7,103.1 157.1,107.2 149,107.2 148.5,110.6 156.7,110.6 156.1,114.7 143.9,114.7 146.6,95.7    158.8,95.7 158.2,99.7 150.1,99.7 149.6,103.1  "></polygon>
      <polygon class="st2" points="166.1,95.7 171.3,107.2 172.9,95.7 177,95.7 174.3,114.7 170.2,114.7 165.1,103.1 163.4,114.7    159.4,114.7 162,95.7  "></polygon>
      <polygon class="st2" points="199.2,95.7 198.7,99.7 193.9,99.7 191.8,114.7 187.7,114.7 189.8,99.7 185.1,99.7 185.7,95.7  "></polygon>
      <polygon class="st2" points="206.6,95.7 203.9,114.7 199.8,114.7 202.5,95.7  "></polygon>
      <path class="st2" d="M214.9,107.9h-2.7l-0.9,6.8h-4.1l2.7-19h8.1c3.2,0,4.8,1.4,4.8,4.2c0,0.4,0,0.8-0.1,1.2l-0.2,1.4   c-0.3,2.2-1.4,3.8-3.1,4.7l1.4,7.5h-4.1L214.9,107.9z M213.3,99.7l-0.6,4.1h4.1c0.9,0,1.4-0.5,1.5-1.4l0.2-1.4c0-0.1,0-0.2,0-0.3   c0-0.7-0.4-1-1.2-1H213.3z"></path>
      <path class="st2" d="M238.5,109.2c-0.5,3.6-2.6,5.4-6.2,5.4h-3.4c-3.2,0-4.8-1.4-4.8-4.2c0-0.4,0-0.8,0.1-1.2l1.2-8.1   c0.5-3.6,2.6-5.4,6.2-5.4h3.4c3.2,0,4.8,1.4,4.8,4.2c0,0.4,0,0.8-0.1,1.2L238.5,109.2z M228.3,109.2c0,0.1,0,0.2,0,0.3   c0,0.7,0.4,1,1.2,1h3.4c0.9,0,1.4-0.5,1.5-1.4l1.2-8.1c0-0.1,0-0.2,0-0.3c0-0.7-0.4-1-1.2-1H231c-0.9,0-1.4,0.5-1.5,1.4   L228.3,109.2z"></path>
      <polygon class="st2" points="245.6,110.6 253.8,110.6 253.2,114.7 241,114.7 243.6,95.7 247.7,95.7  "></polygon>
      <path class="st3" d="M65.7,132.5h-2.9v-1.1h4.1v6.2c-1.3,0.6-2.5,0.8-3.9,0.8c-3.9,0-6.3-2.6-6.3-6.5c0-3.8,2.4-6.5,6.3-6.5   c1,0,2.5,0.2,3.4,0.6l-0.1,1.1c-1-0.5-2.1-0.7-3.2-0.7c-3.2,0-5.1,2.3-5.1,5.4c0,3.2,1.8,5.4,5.1,5.4c0.8,0,2.1-0.1,2.7-0.4V132.5z   "></path>
      <path class="st3" d="M71.9,125.7h3.2c2.2,0,3.7,0.9,3.7,3.2c0,1.6-1.2,2.7-2.8,2.9v0c1,0.1,1.2,0.7,1.6,1.5l2.1,4.8h-1.4l-1.8-4.2   c-0.7-1.6-1.3-1.7-2.4-1.7h-1v5.9h-1.2V125.7z M73.1,131.2h1.3c1.5,0,3.1-0.6,3.1-2.3c0-2-1.5-2.2-3.2-2.2h-1.3V131.2z"></path>
      <path class="st3" d="M88.6,125.4c3.8,0,5.5,3.1,5.5,6.5c0,3.8-1.9,6.5-5.5,6.5c-3.6,0-5.5-2.6-5.5-6.5   C83,128.5,84.8,125.4,88.6,125.4 M84.3,131.9c0,2.7,1.3,5.4,4.3,5.4c2.9,0,4.3-2.7,4.3-5.4s-1.3-5.4-4.3-5.4   C85.6,126.5,84.3,129.3,84.3,131.9"></path>
      <path class="st3" d="M107.3,133.8c0,3.7-2.7,4.6-4.5,4.6c-1.8,0-4.5-0.9-4.5-4.6v-8.1h1.2v8.1c0,2,1.1,3.5,3.3,3.5   c2.2,0,3.3-1.5,3.3-3.5v-8.1h1.2V133.8z"></path>
      <path class="st3" d="M112.1,125.7h3.1c2.3,0,3.9,1.2,3.9,3.5c0,2.3-1.8,3.6-4,3.6h-1.7v5.4h-1.2V125.7z M113.4,131.7h0.9   c1.9,0,3.6-0.2,3.6-2.5c0-1.6-0.9-2.5-2.5-2.5h-2V131.7z"></path>
      <polygon class="st3" points="129.5,125.7 135.5,125.7 135.5,126.7 130.7,126.7 130.7,131.2 135.2,131.2 135.2,132.3 130.7,132.3    130.7,138.2 129.5,138.2  "></polygon>
      <rect x="139.3" y="125.7" class="st3" width="1.2" height="12.5"></rect>
      <polygon class="st3" points="147.5,126.7 143.8,126.7 143.8,125.7 152.5,125.7 152.5,126.7 148.8,126.7 148.8,138.2 147.5,138.2     "></polygon>
      <polygon class="st3" points="155.8,125.7 157.3,125.7 163.7,136.7 163.7,136.7 163.7,125.7 165,125.7 165,138.2 163.3,138.2    157.1,127.4 157,127.4 157,138.2 155.8,138.2  "></polygon>
      <polygon class="st3" points="169.7,125.7 175.8,125.7 175.8,126.7 170.9,126.7 170.9,131.2 175.6,131.2 175.6,132.3 170.9,132.3    170.9,137.1 176.1,137.1 176.1,138.2 169.7,138.2  "></polygon>
      <path class="st3" d="M180.1,136.6c0.7,0.5,1.6,0.7,2.8,0.7c1.5,0,2.8-0.8,2.8-2.6c0-2.5-5.7-2.8-5.7-6c0-2,1.8-3.3,4-3.3   c0.6,0,1.6,0.1,2.5,0.4l-0.2,1.1c-0.6-0.3-1.4-0.5-2.3-0.5c-1.3,0-2.7,0.5-2.7,2.2c0,2.5,5.7,2.5,5.7,6.2c0,2.5-2.2,3.5-4.1,3.5   c-1.2,0-2.2-0.3-2.8-0.5L180.1,136.6z"></path>
      <path class="st3" d="M190.9,136.6c0.7,0.5,1.6,0.7,2.8,0.7c1.5,0,2.8-0.8,2.8-2.6c0-2.5-5.7-2.8-5.7-6c0-2,1.8-3.3,4-3.3   c0.6,0,1.6,0.1,2.5,0.4l-0.2,1.1c-0.6-0.3-1.4-0.5-2.3-0.5c-1.3,0-2.7,0.5-2.7,2.2c0,2.5,5.7,2.5,5.7,6.2c0,2.5-2.2,3.5-4.1,3.5   c-1.2,0-2.2-0.3-2.8-0.5L190.9,136.6z"></path>
</g>
</svg>

</template>

<style scoped lang="scss">
.st0{fill:#13A337;}
.st1{fill:#1E181A;}
.st2{clip-path:url(#SVGID_2_);fill:#1E181A;}
.st3{clip-path:url(#SVGID_2_);fill:#909090;}

.pulsate-fwd {
  -webkit-animation: pulsate-fwd 12s cubic-bezier(0.785, 0.135, 0.150, 0.860) infinite both;
  animation: pulsate-fwd 12s cubic-bezier(0.785, 0.135, 0.150, 0.860) infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-17 10:26:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


</style>