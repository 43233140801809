import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './assets/scss/typography.scss';
import './assets/scss/custom.scss';
import {ApiConfig} from "@/config/api-config";


console.log(process.env.NODE_ENV);
console.log(ApiConfig.notificationServerUrl)

createApp(App).mount('#app');



