<template>
    <div class="container-fluid p-3">
    <div class="row">
      <div class="col-12 split-view__col">
        <CourseInfo :layout="layout" :render-clock="renderClock" :current-course="currentCourse" :location="locationName" :participants-count="participantsCount"></CourseInfo>
      </div>
    </div>
  </div>
  <div class="participants__list">
    <ParticiapntList :participants="participants" :isSingleView="isSingleView"></ParticiapntList>
  </div>
  <CourseInfoNext v-if="nextCourse" :next-course="nextCourse" :location="locationName"></CourseInfoNext>
</template>


<style lang="scss">
@import "src/assets/scss/custom.scss";

/**
########### GLOBAL STYLES ######################
 */
.h1 {
  &__single {
    font-size: 2.7rem;
  }
}

.h2 {
  &__single {
    font-size: 2.2rem;
  }
}

.hr {
  &__headline {
    border-color: $primary;
    border-width: 5px;
    width: calc(50% + 5vw);
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    opacity: 1;
  }
}

.table {
  &__single {
    height: 58vh;

    .table-entry {
      font-size: 1.7rem;
      margin-right: 2rem;
      padding: 0.3rem 0.3rem 0.3rem 0.7rem;
    }
  }
}

.split-view {

  &__col {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__course-container {
    width: calc(100% / 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  &__container {

    height: 100vh;

    h1 {
      font-size: 1.7rem;
    }

    .table-entry {
      font-size: 1.3rem;
      padding: 0.3rem;
      margin-right: 2rem;
      padding-left: 0.7rem;
    }
  }
}

</style>


<script>
import ParticiapntList from "@/components/ParticipantList.vue";
import CourseInfoNext from "@/components/CourseInfoNext.vue";
import CourseInfo from "@/components/CourseInfo.vue";
import {ApiConfig} from "@/config/api-config";

export default {
  name: "CourseComponent",
  components: {
    ParticiapntList,
    CourseInfoNext,
    CourseInfo
  },

  props: {
    location: Number,
    locationName: String,
    courses: Object,
    layout: Number,
    isGray: Boolean,
    isSingleView: Boolean,
    renderClock: Boolean
  },

  data() {
    return {
      currentCourse: null,
      nextCourse: null,
      participants: null,
    }
  },

  mounted() {
    this.bootWebsocketModule();
    this.getCoursesForLocation();
    this.fetchParticipants();
  },

  watch: {
    courses: {
      handler() {
        this.getCoursesForLocation();
      }
    },
  },

  computed: {
    participantsCount() {
        if(this.participants) {
          return this.filteredParticipants.length
        }
        return 0
    },

    filteredParticipants() {

      if(this.participants) {
        return this.participants.filter(participant => {
          return participant.participated === '1';
        })
      }
      return null;

    }
  },

  methods: {

    bootWebsocketModule() {
      this.socket = new WebSocket(ApiConfig.notificationServerUrl);
      const openObject = {
        Cmd: 'WhoIAm',
        Data: {
          "token": ApiConfig.token,
          "device": "display",
          "location": this.location
        }
      }
      this.socket.addEventListener('open', () => {
        this.socket.send(JSON.stringify(openObject));
      })
      this.socket.addEventListener('message', () => {
        console.log('updated list for')
        this.fetchParticipants();
      })
    },

    getCoursesForLocation() {
      if (this.courses && this.courses.current) {
        for (let course of this.courses.current) {
          if (course.locationId == this.location) {
            this.currentCourse = course;
          }
        }
      } else {
        this.currentCourse = null;
        this.participants = null;
      }

      if (this.courses) {
        for (let course of this.courses.next) {
          if (course.locationId == this.location) {
            this.nextCourse = course;
            break;
          }
        }
      } else {
        this.nextCourse = null;
      }

      this.fetchParticipants();

      this.readyToRender = true;
    },

    async fetchParticipants() {
      try {

        if (!this.currentCourse) {
          return;
        }
        const response = await fetch(ApiConfig.getUrl + ApiConfig.endpoints.getParticipants, {
          method: 'POST',

          body: JSON.stringify({
            token: ApiConfig.token,
            payload: {sessionId: this.currentCourse.uid}
          })
        });

        const results = await response.json();
        this.participants = results[0].payload.participants;
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("Server Error:", err)
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("Network Error:", err)
        } else {
          console.log("Client Error:", err)
        }
      }
    },

  }
}
</script>
