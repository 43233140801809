<template>
  <div class="container-fluid p-3 position-relative">
    <div class="row">
      <div class="col-12 split-view__col">
        <div class="row">
          <div class="col-12 title">
            <div class="">
              <h2 class="green" :class="{'h2__single': isSingleView}">Kraftkammer</h2>
            </div>
            <div class="clock">{{ clock }}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="participants__list">
    <WeightChamberList :timeslots="reservedTimeslots" headline="Reservierungen" :is-single-view="isSingleView" render-info></WeightChamberList>
  </div>
  <div class="participants__list">
    <WeightChamberList :timeslots="checkedInTimeslots" headline="Derzeit eingecheckt" :is-single-view="isSingleView"></WeightChamberList>
  </div>
  <div> </div>
</template>


<style lang="scss">

.clock {
  position: absolute;
  right: 1rem;
  top: 0;
  font-size: 4rem;
  font-weight: 500;
}

.weightchamber {
  &__row {
    margin-top: 8rem !important;
  }
}

.green {
  color: #28a43a;
}

@import "src/assets/scss/custom.scss";

/**
########### GLOBAL STYLES ######################
 */
.h1 {
  &__single {
    font-size: 2.7rem;
  }
}

.h2 {
  &__single {
    font-size: 2.2rem;
  }
}

.hr {
  &__headline {
    border-color: $primary;
    border-width: 5px;
    width: calc(50% + 5vw);
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    opacity: 1;
  }
}

.table {
  &__single {
    height: 58vh;

    .table-entry {
      font-size: 1.7rem;
      margin-right: 2rem;
      padding: 0.3rem 0.3rem 0.3rem 0.7rem;
    }
  }
}

.split-view {

  &__col {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__course-container {
    width: calc(100% / 2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  &__container {

    height: 100vh;

    h1 {
      font-size: 1.7rem;
    }

    .table-entry {
      font-size: 1.3rem;
      padding: 0.3rem;
      margin-right: 2rem;
      padding-left: 0.7rem;
    }
  }
}


</style>


<script>
import WeightChamberList from "@/components/WeightChamberList.vue";
import {ApiConfig} from "@/config/api-config";

export default {
  name: "WeightChamberComponent",
  components: {
    WeightChamberList
  },

  props: {
    isSingleView: Boolean,
  },

  data() {
    return {
      clock: null,
      timeslots: null,
      clockInterval: null,
      clockCounter: 0
    }
  },

  mounted() {

    this.bootWebsocketModule();

    this.startClock();

    this.fetchTimeslots();
    setInterval(() => {
      this.fetchTimeslots();
    }, 60000); //1 minute


  },

  computed: {
    reservedTimeslots() {
      if(this.timeslots) {
        return this.timeslots.filter(timeslot => {
          return !timeslot.participated;
        })
      }
      return null;
    },

    checkedInTimeslots() {
      if(this.timeslots) {
        return this.timeslots.filter(timeslot => {
          return timeslot.participated;
        })
      }
      return null;
    }
  },

  methods: {

    startClock() {

      this.clockInterval = setInterval(() => {
        this.updateClock();
        this.clockCounter++;

        if(this.clockCounter > 14000) { //~ 4h
          clearInterval(this.clockInterval);
          this.startClock();
        }
      }, 1000);


    },

    bootWebsocketModule() {
      this.socket = new WebSocket(ApiConfig.notificationServerUrl);
      const openObject = {
        Cmd: 'WhoIAm',
        Data: {
          "token": ApiConfig.token,
          "device": "display",
          "location": 5
        }
      }
      this.socket.addEventListener('open', () => {
        this.socket.send(JSON.stringify(openObject));
      })
      this.socket.addEventListener('message', () => {
        this.fetchTimeslots();
      })
    },

    async fetchTimeslots() {
      try {
        const response = await fetch(ApiConfig.getUrl + ApiConfig.endpoints.timeslots, {
          method: 'POST',

          body: JSON.stringify({
            token: ApiConfig.token,
            payload: {}
          })
        });
        const results = await response.json();
        this.timeslots = results[0].payload.timeslots;
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("Server Error:", err)
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("Network Error:", err)
        } else {
          console.log("Client Error:", err)
        }
      }
    },

    getTime() {

      const time = new Date();

      const formatter = Intl.DateTimeFormat('de-AT', {
        hour: "2-digit",
        minute: "2-digit"
      });
      console.log(time);
      return formatter.format(time);
    },

    updateClock() {
      this.clock = this.getTime();
    },
  }
}
</script>
