<script>

import BaseWrapper from "@/components/BaseWrapper.vue";

export default {
  name: 'App',
  components: {
    BaseWrapper
  }
}
</script>

<template>
  <BaseWrapper></BaseWrapper>
</template>


<style lang="scss">

</style>
