<script>

export default {
  name: 'WeightChamberList',
  components: {},
  props: {
    timeslots: Array,
    headline: String,
    isSingleView: Boolean,
    renderInfo: Boolean
  },

  data() {
    return {}
  },

  watch: {
    timeslots: {
      handler() {
      }
    }
  },

  methods: {
    getTime(dateTime) {

      const time = new Date(dateTime.date);

      const formatter = Intl.DateTimeFormat('de-AT', {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: dateTime.timezone
      });

      return formatter.format(time);
    }
  },

}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1 class="fw-bolder" :class="{'h1__single': isSingleView}">{{headline}} <span v-if="!isSingleView && renderInfo" class="course-info__checkin-time">Check-In ab: 35 Minuten vor Trainingsbeginn</span></h1>
        <hr class="hr__headline mt-1 mb-3">
      </div>
    </div>

    <div class="participants-table" :class="{'table__single': isSingleView}">

      <div v-for="timeslot in timeslots" :key="timeslot.uid" class="table-entry scale-in-hor-left">
        {{ getTime(timeslot.startTime) }} - {{ getTime(timeslot.endTime) }} Uhr: {{ timeslot.name }}
      </div>

    </div>
  </div>
</template>


<style scoped lang="scss">

@import "src/assets/scss/custom.scss";
.h1 {
  &__single {
    font-size: 2.7rem;
  }
}

.h2 {
  &__single {
    font-size: 2.2rem;
  }
}


.course-info {
  font-size: 1.4rem;

  &--single {
    font-size: 2.2rem;
  }

  &__checkin-time {
    font-size: 1rem;
    font-weight: 400;

    &--single {
      font-size: 1.4rem;
    }
  }
}

.hr {
  &__headline {
    border-color: $primary;
    border-width: 5px;
    width: calc(50% + 5vw);
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    opacity: 1;
  }
}

.participants-table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 35vh;
}

.table-entry {
  padding: 0.75rem;
  font-size: 1.25rem;
  margin-right: 2rem;

  &:nth-child(odd) {
    background: #28a43a linear-gradient(180deg, #48b258, #28a43a) repeat-x;
    border-color: #28a43a;
    color: white;
  }
}


.scale-in-hor-left {
  -webkit-animation: scale-in-hor-left 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-hor-left 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

</style>
