export const ApiConfig = {

    isSecure: true,
    endpoint: '/api/v1/',
    host: process.env.NODE_ENV === 'dev' ? 'wbt-2021.ddev.site' : 'www.wir-bewegen-tirol.at',
    token: 'amfOdBqCIsvOvgHnCTsqNobznVC8Q5HH9ah3JOdV2lk2iQMiYtQfdsytB6iVBtHV',
    notificationServerUrl: process.env.NODE_ENV === 'development' ? "ws://localhost:3001" : "ws://reitter.it-wolf.at:3666",
    endpoints: {
        getDeviceConfig: 'configurations',
        checkPermission: 'user',
        checkPermissionByUsername: 'checkByUsername',
        checkin: 'userCheckin',
        currentCourses: 'courses/current',
        checkInUserTypo3: 'portal/checkInUser?type=1515762357',
        getParticipants: 'courses/participants',
        timeslots: 'weightchamber/all-timeslots',
    },

    getUrl: process.env.NODE_ENV === 'development' ? 'https://wbt-2021.ddev.site/api/v1/' : 'https://www.wir-bewegen-tirol.at/api/v1/',
};
