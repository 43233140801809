<script>

export default {
  name: 'CourseInfoNext',
  components: {},

  props: {
    nextCourse: Object,
    isSingleView: Boolean
  },

  data() {
    return {
    }
  },

  methods: {
    timeFormatter(time) {
      const formatter = Intl.DateTimeFormat('de-AT', {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Vienna"
      });

      return formatter.format(time)
    },

  },

  computed: {
    courseNameNext() {
      if (this.nextCourse && this.nextCourse.course) {
        return this.nextCourse.course.name;
      }
      return 'derzeit findet kein Kurs statt'
    },

    timeNext() {
      if (this.nextCourse && this.nextCourse.course) {

        const startTime = new Date(this.nextCourse.startTime.date)
        const endTime = new Date(this.nextCourse.endDateTime.date)

        return `${this.timeFormatter(startTime)} - ${this.timeFormatter(endTime)} Uhr`
      }
      return '---'
    },


    checkInTime() {

      if (this.nextCourse && this.nextCourse.course) {

        let startTime = new Date(this.nextCourse.startTime.date)
        startTime.setMinutes(startTime.getMinutes() - 35);
        return `Check-In ab: ${this.timeFormatter(startTime)} Uhr`;
      }
      return ''

    }
  }
}
</script>

<template>
  <div v-if="nextCourse" class="container-fluid mb-5">
    <div class="row">
      <div class="col-12">
        <h1 class="mb-0" :class="{'course-info--single': isSingleView}">Nächster Kurs <span class="course-info__checkin-time" :class="{'course-info__checkin-time--single': isSingleView}">{{checkInTime}}</span></h1>
        <hr class="hr__headline mt-1 mb-3">
        <span class="course-info"> {{ courseNameNext }}</span> <span>{{ timeNext }}</span>
      </div>
    </div>
  </div>

</template>


<style scoped lang="scss">

.course-info {
  font-size: 1.4rem;

  &--single {
    font-size: 2.2rem;
  }

  &__checkin-time {
    font-size: 1rem;
    font-weight: 400;

    &--single {
      font-size: 1.4rem;
    }
  }
}
</style>
